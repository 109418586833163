import React, { lazy, useContext, useState, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeContext } from './projectContext/ThemeContext';
import { getTokenSession, subUser, logout } from './provider/Auth';
// import Loader from './assets/images/loader.gif';
import Lottie from 'lottie-react';
import pageLoaderJson from './assets/images/PageLoader.json';
import LoaderFrontIcon from './assets/images/loader-icon.png';
import ArticleWriter from './pages/ArticleWriter/ArticleWriter';
import AutoMateMeEmail from "./pages/AutoMateMe/AutoMateEmail";
import EmailTemplateBuilder from './pages/EmailTemplateBuilder/EmailTemplateBuilder';
import EmailAutomate from './pages/EmailAutomate/EmailAutomate';
import ReciptListAutomate from './pages/ReciptListAutomate/ReciptListAutomate';
const Frontpage = lazy(() => import('./pages/Frontpage/Frontpage'));
const Project = lazy(() => import('./pages/Project/Project'));
const CreateArticle = lazy(() => import('./pages/CreateArticle/CreateArticle'));
const Login = lazy(() => import('./pages/Login/Login'));
const Signup = lazy(() => import('./pages/Signup/Signup'));
const CopyWriter = lazy(() => import('./pages/CopyWriter/CopyWriter'));
const AutoMateMe = lazy(() => import('./pages/AutoMateMe/AutoMateMe'));
const ChatMe = lazy(() => import('./pages/ChatMe/ChatMe'));
const CodeMe = lazy(() => import('./pages/CodeMe/CodeMe'));
const VoiceMe = lazy(() => import('./pages/VoiceMe/VoiceMe'));
const PictureMe = lazy(() => import('./pages/PictureMe/PictureMe'));
const Setting = lazy(() => import('./pages/Setting/Setting'));



function App() {
  const defaultValue = useContext(ThemeContext);

  // only use for article writer
  const [projectArticleId, setProjectArticleId] = useState("");
  const [articleId, setArticleId] = useState("");

  const parentHandler = (e) => {
    setProjectArticleId(e.projectId);
    setArticleId(e.articleId)
  }

  useEffect(() => {
    if (localStorage.getItem('REACT_TOKEN_AUTH') && subUser()) {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + getTokenSession());
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "Post",
        headers: myHeaders,
        redirect: "follow",
      }

      fetch(
        defaultValue.apiURL + 'users/checkUser',
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.response == null) {
            logout()
          }
        });
    }
  }, [])

  return (
    <Suspense fallback={
      <div className="height-100vh d-flex align-items-center justify-content-center loader-holder">
        <div className='loader-img-holder d-flex align-items-center justify-content-center'>
          <div className='WriteMeLoader'>
            <Lottie animationData={pageLoaderJson} />
          </div>
        </div>
      </div>
    }>
      <ThemeContext.Provider value={defaultValue}>
        <div className="App">
          {getTokenSession() === null ?
            <Routes>
              <Route exact={true} path='*' element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
            :
            <Routes>
              <Route exact={true} path='*' element={<Frontpage />} />
              <Route path="/project" element={<Project parentHandler={parentHandler} />} />
              <Route path="/create-article" element={<CreateArticle />} />
              <Route path="/article-writer" element={<ArticleWriter />} />
              <Route path='/copy-writer' element={<CopyWriter />} />
              <Route path='/blog-article-automateme' element={<AutoMateMe />} />
              <Route path='/article-automateme' element={<AutoMateMeEmail />} />
              <Route path='/chatme' element={<ChatMe />} />
              <Route path='/voiceme' element={<VoiceMe />} />
              <Route path='/pictureme' element={<PictureMe />} />
              <Route path='/setting' element={<Setting />} />
              <Route path='/email-automateme' element={<EmailAutomate />} />
              <Route path='/recipt-list-automateme' element={<ReciptListAutomate />} />
              <Route path='/email-builder' element={<EmailTemplateBuilder />} />
            </Routes>
          }
        </div>
      </ThemeContext.Provider>
    </Suspense>
  );
}

export default App;