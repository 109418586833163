import { useState, useContext, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ArticleImage from './../../assets/images/article-publishing.png';
import PictureImage from './../../assets/images/picture.png';
import EmailImage from './../../assets/images/email.png';
import CloseButton from 'react-bootstrap/CloseButton';

export default function AutomateMeInitialModal(props) {

    const [automateState, setAutomateState] = useState({
        text: ""
    })

    const checkHandler = (e) => {
        setAutomateState(prev => {
            return {
                ...prev,
                text: e.target.value
            }
        })
    }


    return (
        <>
            <Modal show={props.show} className='automate-modal' centered>
                <Modal.Header>
                    <Modal.Title className='fw-semibold flex-column flex-grow-1 pb-1 gap-2'>
                        <span className="icon card-square-icon icon-lg icon-automate mb-1"></span>
                        AutomateMe
                        <CloseButton onClick={() => props.setAutomateInitialModalShow(false)} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <div className='d-flex flex-wrap card-automate-wrap'>
                            <Form.Group className="mb-3 card-automate-item">
                                <input type="radio" name="automate-card" id="article publishing" onClick={checkHandler} value={"AI for Blog/Article Publishing"} />
                                <label htmlFor="article publishing" className='d-flex flex-column card-automate'>
                                    <img className='img' src={ArticleImage} alt="" />
                                    <span>AI for Blog/Article Publishing</span>
                                </label>
                            </Form.Group>
                            <Form.Group className="mb-3 card-automate-item">
                                <input type="radio" name="automate-card" id="email Newsletter Campaigns" onClick={checkHandler} value={"AI for Email & Newsletter Campaigns"} disabled/>
                                <label htmlFor="email Newsletter Campaigns" className='d-flex flex-column card-automate'>
                                    <img className='img' src={EmailImage} alt="" />
                                    <span>AI for Email & Newsletter Campaigns</span>
                                </label>
                            </Form.Group>
                            <Form.Group className="mb-3 card-automate-item">
                                <input type="radio" name="automate-card" id="Social Media Postings" onClick={checkHandler} value={"Social Media Postings"} disabled />
                                <label htmlFor="Social Media Postings" className='d-flex flex-column card-automate'>
                                    <img className='img' src={PictureImage} alt="" />
                                    <span>AI for Social Media Postings</span>
                                </label>
                            </Form.Group>
                        </div>
                        {automateState.text.length > 0 ? <div className='text-center'><span className='create-highlight-title d-inline-block'><strong className='h5 fw-medium'>Click "Create New Instance" to start using "{automateState.text}" and schedule your content.</strong></span></div> : ""}
                        <Button disabled={automateState.text.length > 0 ? false : true} className='px-3 mt-4 mx-auto' onClick={() => {
                            props.setAutomateInitialModalShow(false);
                            if (automateState.text === "AI for Blog/Article Publishing") {
                                window.location.href = "/blog-article-automateme";
                            } else if (automateState.text === "AI for Email & Newsletter Campaigns") {
                                window.location.href = "/email-automateme";
                            }
                        }}>
                            <span className='icon-lg icon-add-circle'></span>
                            Create new instance
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}