import { getTokenSession } from './Auth';

const getScheduledArticles = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/my-scheduled-articles`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            // Categorize articles based on status
            const scheduled = [];
            const generated = [];
            const designReviewed = [];
            const seoReviewed = [];
            const reviewed = [];
            const published = [];
            const cancelled = [];
            const projects = [];

            result.response.forEach((article) => {
                if (article.project && !projects.includes(article.project.title)) {
                    projects.push(article.project.title);
                }
                switch (article.status) {
                    case 'Scheduled':
                        scheduled.push(article);
                        break;
                    case 'Generated':
                        generated.push(article);
                        break;
                    case 'Design Reviewed':
                        designReviewed.push(article);
                        break;
                    case 'Content Reviewed':
                        seoReviewed.push(article);
                        break;
                    case 'Final Reviewed':
                        reviewed.push(article);
                        break;
                    case 'Published':
                        published.push(article);
                        break;
                    case 'Cancelled':
                        cancelled.push(article);
                        break;
                    default:
                        break;
                }
            });

            return {
                success: true,
                articles: result.response,
                scheduled,
                generated,
                designReviewed,
                seoReviewed,
                reviewed,
                published,
                cancelled,
                projects,
            };
        } else {
            return { success: false, error: "Failed to retrieve scheduled articles" };
        }
    } catch (error) {
        console.error("Error fetching scheduled articles:", error);
        return { success: false, error: "API request failed" };
    }
};

const generateScheduledArticle = async (ThemeContextData, articleId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        article_id: articleId,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/generate-scheduled-article`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, data: result };
        } else {
            return { success: false, error: "Failed to generate the article" };
        }
    } catch (error) {
        console.error("Error generating article:", error);
        return { success: false, error: "API request failed" };
    }
};

const updateArticleStatus = async (ThemeContextData, parentId, status) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        articleId: parentId,
        status: status,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/update-status`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, data: result };
        } else {
            return { success: false, error: "Failed to update status" };
        }
    } catch (error) {
        console.error("Error updating status:", error);
        return { success: false, error: "API request failed" };
    }
};

const deleteArticle = async (ThemeContextData, projectId, articleId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        project_id: projectId,
        article_id: articleId,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/delete-article`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result };
        } else {
            return { success: false, error: "Failed to delete article" };
        }
    } catch (error) {
        console.error("Error deleting article:", error);
        return { success: false, error: "API request failed" };
    }
};

// Provider function for generating article heading
const generateArticleHeadingAutomate = async (state, selectedLangOption, selectedIntentOption, keywordData, AIModalOption, ThemeContextData) => {
    if (state.articleTitle.length > 0 && keywordData.length > 0 && selectedIntentOption !== 'Select Option') {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + getTokenSession());
            myHeaders.append("accept", "application/json");
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                language: selectedLangOption.text,
                tone: selectedIntentOption.text,
                title: state.articleTitle,
                keyword: keywordData,
                GPT: AIModalOption.text,
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: raw,
            };

            const response = await fetch(
                `${ThemeContextData.apiURL}article/article-heading-generate`,
                requestOptions
            );

            const result = await response.json();

            if (result.response.ok) {
                return { success: true, 'data': result.response.data[0] };
            } else if (result.response.message === "balance exhausted") {
                return { success: false, exhausted: true };
            } else {
                return { success: false, error: "Failed to generate article heading" };
            }
        } catch (error) {
            console.error("Error generating article heading:", error);
            return { success: false, error: "API request failed" };
        }
    } else {
        return {
            success: false,
            error: "Validation failed",
            fieldsError: {
                searchIntent: selectedIntentOption === 'Select Option',
                articleTitle: state.articleTitle.length === 0,
                keyword: keywordData.length === 0,
                seedKeyword: false
            }
        };
    }
};


export {
    getScheduledArticles,
    generateScheduledArticle,
    updateArticleStatus,
    deleteArticle,
    generateArticleHeadingAutomate
};