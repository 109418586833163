import React, { useState, useContext } from 'react';

import { HtmlProps, HtmlPropsSchema } from '@usewaypoint/block-html';
import { Box } from '@mui/material';
import BaseSidebarPanel from './helpers/BaseSidebarPanel.tsx';
import TextInput from './helpers/inputs/TextInput.tsx';
import MultiStylePropertyPanel from './helpers/style-inputs/MultiStylePropertyPanel.tsx';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { ThemeContext } from '../../../../../projectContext/ThemeContext.js';
import { getTokenSession } from '../../../../../provider/Auth.js';
import LoaderJSON from '../../../../../assets/images/loading.json';
import Lottie from 'lottie-react';
import { Button } from 'react-bootstrap';
import ReactQuill, { Quill } from 'react-quill';

type HtmlSidebarPanelProps = {
  data: HtmlProps;
  setData: (v: HtmlProps) => void;
};
export default function HtmlSidebarPanel({ data, setData }: HtmlSidebarPanelProps) {
  const [, setErrors] = useState<Zod.ZodError | null>(null);

  const ThemeContextData = useContext(ThemeContext);
  const [generating, setGenerating] = useState(false);
  const [aiHelp, setAiHelp] = useState(false);
  const [fieldCounter, setFieldCounter] = useState(0);
  const [promptValue, setPromptValue] = useState("");
  const [generatedResult, setGeneratedResult] = useState("");

  const quillRef = React.useRef(null);

  const modules = {
    toolbar: [
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic'],
      [{ 'color': [] }],
      ['link'],
      ['clean']
    ],
  };
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'align', 'color', 'script'
  ];

  const chatAPIHandler = () => {

    let newIndexObject = { role: "system", content: "You are a helpful assistant." };
    let messagesDataArr = [{ role: "user", content: data.props?.text + " " + promptValue }];

    messagesDataArr = [newIndexObject].concat(messagesDataArr);

    setGenerating(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    var raw = JSON.stringify({
      usecase: "ChatCall",
      messages: messagesDataArr,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };


    fetch(
      ThemeContextData.apiURL + 'chats/chat-turbo',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.response.data) {
          setGeneratedResult(result.response.data);
          setFieldCounter(1);
        }
        else if (result.response.message === "balance exhausted") {
        }

        setGenerating(false);

      })
      .catch((error) => { });
  }

  const updateData = (d: unknown) => {
    const res = HtmlPropsSchema.safeParse(d);
    if (res.success) {
      setData(res.data);
      setErrors(null);
    } else {
      setErrors(res.error);
    }
  };

  return (
    <BaseSidebarPanel title="Html block">
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={data.props?.contents ?? ''}
        modules={modules}
        formats={formats}
        onChange={(contents) => updateData({ ...data, props: { ...data.props, contents } })}
      // onChangeSelection={handleSelectionChange}
      />

      <Box style={{ textAlign: 'right', marginTop: 5, cursor: 'pointer' }} onClick={() => setAiHelp(!aiHelp)}>AI Help?</Box>

      {aiHelp ?
        <Box style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
          {fieldCounter === 0 ? <>
            <TextField
              label="Enter Prompt"
              type="text"
              value={promptValue}
              onChange={(e) => setPromptValue(e.target.value)}
            />
            {generating ? <Button variant='dark' className='mx-auto' onClick={(e) => { e.preventDefault(); }}>
              <Lottie className='LoaderJSONSVG' animationData={LoaderJSON} />
              Generating...
            </Button> :
              <Button
                variant='primary' className='mx-auto' onClick={chatAPIHandler}>
                <span className='icon-lg icon-article'></span>
                Generate Prompt
              </Button>
            }

          </> : fieldCounter === 1 ?
            <>
              <TextField
                label="Generated prompt"
                type="text"
                value={generatedResult}
                onChange={(e) => generatedResultHandler(e.target.value)}
              />
              <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                  variant='primary' onClick={() => setFieldCounter(0)}>
                  <span className='icon-lg icon-article'></span>
                  Re-generate
                </Button>
                <Button
                  variant='secondary' onClick={() => navigator.clipboard.writeText(generatedResult)}>
                  <span className='icon-lg icon-article'></span>
                  Copy
                </Button>
              </Stack>
            </> : ""
          }
        </Box>
        : ""}

      <MultiStylePropertyPanel
        names={['color', 'backgroundColor', 'fontFamily', 'fontSize', 'textAlign', 'padding']}
        value={data.style}
        onChange={(style) => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
}
