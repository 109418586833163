import React, { useContext, useEffect, useState } from 'react';

import { Box, Divider, Drawer, Stack, Typography } from '@mui/material';

import { useSamplesDrawerOpen } from '../documents/editor/EditorContext.tsx';
import { useLocation } from 'react-router-dom';
import SidebarButton from './SidebarButton.tsx';
import logo from './waypoint.svg';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ThemeContext } from '../../../projectContext/ThemeContext';
import { GetAllEmailBuilderController } from '../../../provider/EmailBuilderControlller.js';
import dottedLoaderSmall from '../../../assets/images/dottedLoaderSmall.gif';
export const SAMPLES_DRAWER_WIDTH = 240;

export default function SamplesDrawer() {
  const samplesDrawerOpen = useSamplesDrawerOpen();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ThemeContextData = useContext(ThemeContext);
  const [templates, setTemplates] = useState([]);
  const [loader, setLoader] = useState(false);

  const getTemplateList = async () => {
    const result = await GetAllEmailBuilderController(ThemeContextData);
    if (result.success) {
      setTemplates(result.data);
      setLoader(true)
    }
  }

  useEffect(() => {
    getTemplateList()
  })


  const createTemplateHandler = () => {

  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={samplesDrawerOpen}
      sx={{

      }}
    >
      <div className='p-2 template-list-panel'>
        <Typography variant="h6" component="h1" sx={{ p: 0.75 }}>
          Pre-Build Templates
        </Typography>
        <ul className='d-flex flex-column list-unstyled folder-list'>
          {!loader ? <li style={{ display: 'flex', justifyContent: 'center', }}>
            <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
          </li> :
            <>
              {templates && templates.length > 0 && templates.map(item =>
                <li className={queryParams.get('template_id') == item.id ? "default" : ""} style={{ cursor: 'pointer' }}>
                  <a href="">
                    <div className='d-flex flex-grow-1 align-left' onClick={() => window.location.href = `/email-builder?template_id=${item.id}`}>
                      <span class="icon-file"></span>
                      <span className='text'>{item.template_name}</span>
                    </div>
                  </a>
                </li>
              )}
            </>
          }
        </ul>
        <div className='p-2'>
          {/* <Button variant="primary" onClick={createTemplateHandler}>Creare a template</Button> */}
        </div>
      </div>
    </Drawer>
  );
}
