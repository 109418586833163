import { getTokenSession } from './Auth';

const getProjects = async (ThemeContextData, setProjectData, setChatLoader) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(ThemeContextData.apiURL + 'projects/get-project', requestOptions);
        const result = await response.json();
        if (result.response.ok) {
            setProjectData(result.response.data);
            setChatLoader(prevState => ({
                ...prevState,
                sidebarLoader: true
            }));
        } else {
            setChatLoader(prevState => ({
                ...prevState,
                sidebarLoader: true
            }));
        }

        // Handle scrolling to the default element after a short delay
        setTimeout(() => {
            const defaultElement = document.querySelector('.folder-list .default');
            if (defaultElement) {
                const getDefaultElement = defaultElement.getAttribute("data-element");
                document.getElementById('projectLeftListScroll').scrollTop = getDefaultElement * 40;
            }
        }, 0);

    } catch (error) {
        console.error('Error fetching projects:', error);
        setChatLoader(prevState => ({
            ...prevState,
            sidebarLoader: true
        }));
    }
};

const projectCreateHandlerFunction = async (
    ThemeContextData,
    data,
    setCreateProjectBtn,
    setGetProjectId,
    projectArticlesHandler,
    setCreateProjectCompleted,
    getProjects,
    addProjectCloseModal,  // Passed as a parameter
    setCreateProjectCompletedError,
    setChatLoader,
    setProjectData
) => {
    setCreateProjectBtn(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        title: data.title,
        keyword: data.keyword,
        category: data.category,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'projects/create-project',
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            setCreateProjectBtn(false);
            setGetProjectId(result.response.id);

            projectArticlesHandler(result.response.id);

            setCreateProjectCompleted(true);

            await getProjects(ThemeContextData,setProjectData ,setChatLoader);

            // Call passed close modal function
            addProjectCloseModal();

            setCreateProjectCompletedError(false);

            const projectScrollElement = document.getElementById('projectLeftListScroll');
            if (projectScrollElement) {
                projectScrollElement.scrollTop = 0;
            }

        } else {
            setCreateProjectBtn(false);
            setCreateProjectCompleted(false);
            setCreateProjectCompletedError(true);
        }

    } catch (error) {
        console.error("Error creating project:", error);
        setCreateProjectBtn(false);
        setCreateProjectCompleted(false);
        setCreateProjectCompletedError(true);
    }
};

const getProjectDataFunction = async (
    ThemeContextData,
    projectId,
    setGetProjectUpdateId,
    setGetProjectTitle,
    setGetProjectKeywords,
    setShowProjectEditModal
) => {
    setGetProjectUpdateId(projectId);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        project_id: projectId,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(ThemeContextData.apiURL + 'projects/edit-project', requestOptions);
        const result = await response.json();

        if (result.response.ok) {
            const projectData = result.response.result.project[0];

            // Set the title and keywords
            setGetProjectTitle(projectData.title);
            setGetProjectKeywords(result.response.result.keyword);

            // Show the project edit modal
            setShowProjectEditModal(true);
        } else {
            console.error("Failed to fetch project data:", result.response.message);
        }
    } catch (error) {
        console.error("Error fetching project data:", error);
    }
};

const projectUpdateHandlerFunction = async (
    ThemeContextData,
    getProjectUpdateId,
    data,
    getProjects,
    editProjectCloseModal,
    setGetProjectKeywords,setProjectData, setChatLoader
) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        id: getProjectUpdateId,
        title: data.title,
        keyword: data.keyword,
        category: data.category,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(ThemeContextData.apiURL + 'projects/update-project', requestOptions);
        const result = await response.json();

        if (result.response.ok) {
            // Call getProjects to refresh the project list
            await getProjects(ThemeContextData, setProjectData, setChatLoader);

            // Close the modal after successful update
            editProjectCloseModal();

            // Clear project keywords after the update
            setGetProjectKeywords([]);
        } else {
            console.error("Error updating project:", result.response.message);
        }
    } catch (error) {
        console.error("Error in project update:", error);
    }
};

const projectArticlesHandlerFunction = async (
    ThemeContextData,
    projectId,
    setChatLoader,
    setGetProjectId,
    setArticleCardList,
    setProjectCategories
) => {
    // Show the loader before making the request
    setChatLoader((prevState) => ({
        ...prevState,
        mainbarLoader: true
    }));

    setGetProjectId(projectId);
    localStorage.setItem("WriteMeProjectId", projectId);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        project_id: projectId,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(ThemeContextData.apiURL + 'article/get-aricle', requestOptions);
        const result = await response.json();

        if (result.response) {
            let array = [result.response.result, result.response.success.audios, result.response.success.images];
            const sortedDataDesc = array.flat().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            // Update the article card list with sorted data
            setArticleCardList(sortedDataDesc);

            // Set the project categories
            setProjectCategories(result.response.categories);
        }

    } catch (error) {
        console.error("Error fetching articles:", error);
    } finally {
        // Hide the loader once the request is completed
        setChatLoader((prevState) => ({
            ...prevState,
            mainbarLoader: false
        }));
    }
};


const deleteHandlerFunction = async (ThemeContextData, getProjectId, setProjectDeleteModal, setProjectMessageModal, setGetProjectId, setProjectData, setChatLoader, handleFetchProjects, nextTab) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        project_id: getProjectId,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'projects/delete-project',
            requestOptions
        );
        const result = await response.json();

        setProjectDeleteModal(false);
        setProjectMessageModal(true);

        // Re-fetch the project list after deletion
        await getProjects(ThemeContextData, setProjectData, setChatLoader);

        // Proceed to the next tab after a brief delay
        setTimeout(nextTab, 1500);
        // Clear the project ID after deletion
        setGetProjectId("");

    } catch (error) {
        console.error('Error deleting project:', error);
    }
};

const userProject = async (ThemeContextData, setProjectDataArray) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(ThemeContextData.apiURL + 'projects/user-projects', requestOptions);
        const result = await response.json();

        if (result.ok) {
            const finalArray = result.response.projects.map(item => ({
                value: item.id,
                text: item.title
            }));
            return finalArray;

        }

    } catch (error) {
        console.error("Error fetching user projects:", error);
    }
};


const getProjectsAutomate = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${ThemeContextData.apiURL}projects/get-project`, requestOptions);
        const result = await response.json();

        if (response.ok && result.response.ok) {
            let projectData = [];

            result.response.data.forEach((item) => {
                if (item.title !== "") {
                    projectData.push({
                        value: `${item.id}`,
                        text: `${item.title}`,
                        icon: <span className='icon-project'></span>
                    });
                }
            });

            return {
                success: true,
                data: {
                    projectData,
                    firstProjectId: result.response.data[0]?.id,
                }
            };
        } else {
            return { success: false, error: "Failed to fetch projects" };
        }
    } catch (error) {
        console.error("Error fetching projects:", error);
        return { success: false, error: "API request failed" };
    }
};



export {
    getProjects,
    deleteHandlerFunction,
    projectCreateHandlerFunction,
    getProjectDataFunction,
    projectArticlesHandlerFunction,
    projectUpdateHandlerFunction,
    userProject,
    getProjectsAutomate
};
