import React, { useReducer, useState } from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { CloseButton } from 'react-bootstrap';
import AddPlus from '../../assets/images/add-plus.png';

const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    user_name: "",
}

const formReducer = (state, action) => {
    switch (action.type) {
        case "Handle Input Text":
            const { buttonDisabled, ...inputFields } = state;

            // update the field
            const form = {
                ...inputFields,
                [action.field]: action.value,
            };

            let disabled;

            return {
                ...form,
                buttonDisabled: disabled
            };
        default:
            return state;
    }
}

export default function EditEmailForReciptModal(props) {

    const [state, dispatch] = useReducer(formReducer, initialState);

    const handleTextChange = e => {
        dispatch({
            type: 'Handle Input Text',
            field: e.target.name,
            value: e.target.value
        })
    }

    return (
        <div>
            <Modal className='credit-report-modal' show={props.show} centered>
                <Modal.Header>
                    <Modal.Title className='fw-semibold flex-column flex-grow-1 pb-1 gap-2'>
                        <span className="icon card-square-icon icon-lg icon-automate mb-1"></span>
                        Edit Select Menu
                        <CloseButton onClick={() => {
                            props.setEditEmailReciptModalShow(false);
                        }} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="first_name" placeholder="First Name" value={state.first_name} onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="last_name" placeholder="Last Name" value={state.last_name} onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="text" name="email" value={state.email} placeholder="Email" onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control type="text" name="user_name" value={state.user_name} placeholder="User Name" onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className='modal-footer pt-0 px-0 border-top-0 justify-content-between'>
                            <Button
                                disabled={state.first_name.length > 0 &&
                                    state.last_name.length > 0 &&
                                    state.email.length > 0 &&
                                    state.user_name.length > 0 ? false : true}
                                className='px-3 mx-auto'
                                onClick={() => props.setEditEmailReciptModalShow(false)}>
                                <span className='icon icon-save'></span>
                                Save & Close
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}
