import React, { useState, useContext } from 'react';

import { TextProps, TextPropsSchema } from '@usewaypoint/block-text';
import { Box } from '@mui/material';
import BaseSidebarPanel from './helpers/BaseSidebarPanel.tsx';
import BooleanInput from './helpers/inputs/BooleanInput.tsx';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import TextInput from './helpers/inputs/TextInput.tsx';
import MultiStylePropertyPanel from './helpers/style-inputs/MultiStylePropertyPanel.tsx';
import { ThemeContext } from '../../../../../projectContext/ThemeContext.js';
import { getTokenSession } from '../../../../../provider/Auth.js';
import LoaderJSON from '../../../../../assets/images/loading.json';
import Lottie from 'lottie-react';
import { Button } from 'react-bootstrap';

type TextSidebarPanelProps = {
  data: TextProps;
  setData: (v: TextProps) => void;
};
export default function TextSidebarPanel({ data, setData }: TextSidebarPanelProps) {

  const ThemeContextData = useContext(ThemeContext);
  const [generating, setGenerating] = useState(false);
  const [aiHelp, setAiHelp] = useState(false);
  const [fieldCounter, setFieldCounter] = useState(0);
  const [promptValue, setPromptValue] = useState("");
  const [generatedResult, setGeneratedResult] = useState("");

  const [, setErrors] = useState<Zod.ZodError | null>(null);

  const updateData = (d: unknown) => {
    const res = TextPropsSchema.safeParse(d);
    if (res.success) {
      setData(res.data);
      setErrors(null);
    } else {
      setErrors(res.error);
    }
  };


  const chatAPIHandler = () => {

    let newIndexObject = { role: "system", content: "You are a helpful assistant." };
    let messagesDataArr = [{ role: "user", content: data.props?.text + " " + promptValue }];

    messagesDataArr = [newIndexObject].concat(messagesDataArr);

    setGenerating(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");


    var raw = JSON.stringify({
      usecase: "ChatCall",
      messages: messagesDataArr,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };


    fetch(
      ThemeContextData.apiURL + 'chats/chat-turbo',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.response.data) {
          setGeneratedResult(result.response.data);
          setFieldCounter(1);
        }
        else if (result.response.message === "balance exhausted") {
        }

        setGenerating(false);

      })
      .catch((error) => { });
  }

  return (
    <BaseSidebarPanel title="Text block">
      <TextInput
        label="Content"
        rows={5}
        defaultValue={data.props?.text ?? ''}
        onChange={(text) => updateData({ ...data, props: { ...data.props, text } })}
      />
      <Box style={{ textAlign: 'right', marginTop: 5, cursor: 'pointer' }} onClick={() => setAiHelp(!aiHelp)}>AI Help?</Box>

      {aiHelp ?
        <Box style={{ gap: 15, display: 'flex', flexDirection: 'column' }}>
          {fieldCounter === 0 ? <>
            <TextField
              label="Enter Prompt"
              type="text"
              value={promptValue}
              onChange={(e) => setPromptValue(e.target.value)}
            />
            {generating ? <Button variant='dark' className='mx-auto' onClick={(e) => { e.preventDefault(); }}>
              <Lottie className='LoaderJSONSVG' animationData={LoaderJSON} />
              Generating...
            </Button> :
              <Button
                variant='primary' className='mx-auto' onClick={chatAPIHandler}>
                <span className='icon-lg icon-article'></span>
                Generate Prompt
              </Button>
            }

          </> : fieldCounter === 1 ?
            <>
              <TextField
                label="Generated prompt"
                type="text"
                value={generatedResult}
                onChange={(e) => generatedResultHandler(e.target.value)}
              />
              <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                  variant='primary' onClick={() => setFieldCounter(0)}>
                  <span className='icon-lg icon-article'></span>
                  Re-generate
                </Button>
                <Button
                  variant='secondary' onClick={() => navigator.clipboard.writeText(generatedResult)}>
                  <span className='icon-lg icon-article'></span>
                  Copy
                </Button>
              </Stack>
            </> : ""
          }
        </Box>
        : ""}
      <BooleanInput
        label="Markdown"
        defaultValue={data.props?.markdown ?? false}
        onChange={(markdown) => updateData({ ...data, props: { ...data.props, markdown } })}
      />

      <MultiStylePropertyPanel
        names={['color', 'backgroundColor', 'fontFamily', 'fontSize', 'fontWeight', 'textAlign', 'padding']}
        value={data.style}
        onChange={(style) => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
}
