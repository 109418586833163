import React, { useReducer, useState } from 'react'
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { CloseButton } from 'react-bootstrap';
import AddPlus from '../../assets/images/add-plus.png';
import {createList} from "../../provider/ReciptListController";
import {ThemeContext} from "../../projectContext/ThemeContext";

const initialState = {
    list_name: "",
    description: ""
}

const formReducer = (state, action) => {
    switch (action.type) {
        case "Handle Input Text":
            const { buttonDisabled, ...inputFields } = state;

            // update the field
            const form = {
                ...inputFields,
                [action.field]: action.value,
            };

            let disabled;

            return {
                ...form,
                buttonDisabled: disabled
            };
        default:
            return state;
    }
}

export default function AddNewListReciptModal(props) {

    const [state, dispatch] = useReducer(formReducer, initialState);

    const handleTextChange = e => {
        dispatch({
            type: 'Handle Input Text',
            field: e.target.name,
            value: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        console.log("List Name:", state.list_name);
        console.log("Description:", state.description);
        try {
            const result = await createList(props.themeContext, state);
            console.log(result);
            await props.getListData();
            props.setAddNewListReciptModalShow(false)
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <div>
            <Modal className='credit-report-modal' show={props.show} centered>
                <Modal.Header>
                    <Modal.Title className='fw-semibold flex-column flex-grow-1 pb-1 gap-2'>
                        <span className="icon card-square-icon icon-lg icon-add-circle mb-1"></span>
                        Add New List
                        <CloseButton onClick={() => {
                            props.setAddNewListReciptModalShow(false);
                        }} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>List Name</Form.Label>
                                    <Form.Control type="text" name="list_name" placeholder="List Name" value={state.list_name} onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="text" name="description" placeholder="Description" value={state.description} onChange={handleTextChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className='modal-footer pt-0 px-0 border-top-0 justify-content-between'>
                            <Button
                                disabled={state.list_name.length > 0 && state.description.length > 0 ? false : true}
                                className='px-3 mx-auto'
                                type="submit"
                                // onClick={() => handleSubmit(e)}
                            >
                                <span className='icon icon-save'></span>
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}
