import Modal from 'react-bootstrap/Modal';
import Trash from './../../assets/images/Trash.png';

export default function MessageModal(props) {

    return (
        <>
            <Modal className='MessageModal' show={props.show} centered>
                <Modal.Header >
                    <Modal.Title className='fw-semibold'>
                        {props && props.image ? <img src={props.image} className='img-responsove' alt='trash-image' /> :
                            <img src={Trash} className='img-responsove' alt='trash-image' />
                        }
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </>
    )
}