import { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TimeCalendar from './../../assets/images/time-calendar.png';
import Form from 'react-bootstrap/Form';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Calendar from "react-calendar";
import { getTokenSession } from "../../provider/Auth";
import { ThemeContext } from "../../projectContext/ThemeContext";
import SelectDropDown from '../SelectDropDown/SelectDropDown';
import { CloseButton } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import Select from 'react-select';
import LoaderJSON from './../../assets/images/loading.json';
import Lottie from 'lottie-react';
import { scheduleArticleAPI } from "../../provider/ArticleWriterControllerTools";


export default function InstanceSimpleModal(props) {

    const [publishOption, setPublishOption] = useState({
        id: "",
        text: "",
        value: ""
    });
    const ThemeContextData = useContext(ThemeContext);
    const [date, setDate] = useState(new Date().getDate() + " " + new Date().toLocaleString('default', { month: 'long' }) + ", " + new Date().getFullYear());
    const [userDomains, setUserDomains] = useState([]);
    const [seedKeywordData, setSeedKeywordData] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [timeValue, onTimeChange] = useState('10:00');
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        setUserDomains(props.userDomains);
        console.log(props.domainForCreateInstance);
        if (props.domainForCreateInstance) {
            setPublishOption({
                id: props.domainForCreateInstance.id,
                text: props.domainForCreateInstance.domain_name,
                value: props.domainForCreateInstance.domain_name
            })
        }


        const date = props.scheduleModalTime;
        if (date.split(" ")[1]) {
            const formattedDate = date.split(" ")[0] + " " + date.split(" ")[1].replace(",", ""); // Extract the day and month
            const year = date.split(",")[1].split(" ")[1]; // Extract the year
            const finalDate = `${formattedDate}, ${year}`;
            setDate(finalDate);
        }
    }, [])

    const publishChange = e => {
        setPublishOption(e);
        console.log(e);
    }




    const calenderHandler = e => {
        setDate(e.getDate() + " " + e.toLocaleString('default', { month: 'long' }) + ", " + e.getFullYear())

        console.log(e.getDate() + " " + e.toLocaleString('default', { month: 'long' }) + ", " + e.getFullYear());
        setShowCalendar(false)
    };


    const scheduleArticle = async () => {

        setLoader(true);

        const success = await scheduleArticleAPI(
            ThemeContextData,
            props.articleId,
            date,
            timeValue,
            publishOption.id
        );

        if (success) {
            props.instanceSimpleCloseModal();
        }

        setLoader(false);
    }


    return (
        <>
            <Modal className='CreateInstanceModal customModal' show={props.show} centered>
                <Modal.Header className='flex-wrap justify-content-center'>
                    <div>
                        <Modal.Title className='justify-content-center mb-2 small'> <img src={TimeCalendar} className='img-responsove' alt='Time Calendar' /> Create Schedule</Modal.Title>
                        <p className='mb-0 text-center'>Fill out the details below to schedule your article for generation.</p>
                    </div>
                    <CloseButton onClick={() => props.instanceSimpleCloseModal()} />
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                            <Col xs={12}>
                                <SelectDropDown
                                    class={'select-custom-field'}
                                    label={"Publish Destination"}
                                    value={publishOption}
                                    name={"publishField"}
                                    option={userDomains}
                                    callfunction={publishChange}
                                    type={"icon"} />
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 position-relative date_field field-left-align-icon">
                                    <Form.Label>Publish Date</Form.Label>
                                    <Form.Control
                                        as={"input"}
                                        value={date}
                                        onFocus={() => setShowCalendar(true)}
                                    />
                                    {showCalendar ?
                                        <Calendar
                                            // className={showCalendar ? "" : "hide"}
                                            value={date}
                                            onChange={calenderHandler}
                                        /> : ""}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 position-relative field-left-align-icon time_field">
                                    <Form.Label>Publish Time</Form.Label>
                                    <TimePicker onChange={onTimeChange} value={timeValue} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center">
                                {!loader ?
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            scheduleArticle()
                                        }}
                                    >
                                        <span className='icon-lg icon-magic'></span>
                                        Create this instance
                                    </Button> :
                                    <Button variant='dark' className='mx-auto' onClick={(e) => { e.preventDefault(); }}>
                                        <Lottie className='LoaderJSONSVG' animationData={LoaderJSON} />
                                        Generating...
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}