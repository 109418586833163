import {getTokenSession} from "./Auth";
import {getCreditsFunction} from "./CreditsController";

const savedVoices = async (ThemeContextData) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
    };
    const response = await fetch(
        ThemeContextData.apiURL + 'voice-me/user-saved-audios',
        requestOptions
    );
    const result = await response.json();
    let play = []
    result.response.map((item) => {
        play.push({[item.id]: false})
    });
    return {
        playerList: play,
        savedVoice: result.response
    };
}

const deleteAudio = async (ThemeContextData, selectedAudioId, generatedDelete, savedVoice, setVoiceDeleteMessageModal, setVoiceDeleteModal, setGeneratedVoice, setGeneratedDelete, nextTab) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        id: selectedAudioId,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'voice-me/delete-audio',
            requestOptions
        );
        const result = await response.json();

        if (result.ok) {
            // Update the state and handle logic
            setVoiceDeleteMessageModal(true);
            setVoiceDeleteModal(false);

            // Refresh the saved voices list
            const updatedSavedVoices = await savedVoices(ThemeContextData);

            // Handle the next steps like checking for generatedDelete
            if (generatedDelete) {
                setGeneratedVoice([]);
                setGeneratedDelete(false);
            }

            const savedVoiceLength = savedVoice.flat().length - 1;
            if (savedVoiceLength < 1) {
                setGeneratedVoice([]);
            }

            // Proceed to the next tab after a delay
            setTimeout(nextTab, 1500);
        }
    } catch (error) {
        console.error('Error deleting audio:', error);
    }
};

const generateVoice = async (
    ThemeContextData,
    selectedLangOption,
    voiceType,
    describeImage,
    getIdsOnly,
    projectOption,
    getTTSVersion,
    setVoiceGenerating,
    setBlanceExhaustedModal,
    setIsSubmitting,
    setKey,
    setDescribeImage,
    setIsPlaying1,
    setGeneratedVoice
) => {
    setIsSubmitting(true);
    console.log(voiceType);
    console.log('Provider');
        setVoiceGenerating(true);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        const formData = JSON.stringify({
            lang: selectedLangOption.text,
            voice: voiceType.toLowerCase(),
            desc: describeImage,
            categories: getIdsOnly,
            projectId: projectOption.value,
            gpt: getTTSVersion,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: formData,
        };

        try {
            const response = await fetch(
                ThemeContextData.apiURL + 'voice-me/generate-voice',
                requestOptions
            );
            const result = await response.json();

            setVoiceGenerating(false);

            if (!result.response.ok && result.response.message === "Balance Exhausted") {
                setBlanceExhaustedModal(true);
            } else {
                await getCreditsFunction(ThemeContextData); // Refresh credits
                await savedVoices(ThemeContextData); // Refresh saved voices

                setIsSubmitting(false);
                setKey("current");
                setDescribeImage("");
                setIsPlaying1(false);
                setGeneratedVoice(result.response);
            }
        } catch (error) {
            console.error("Error generating voice:", error);
        }
};


const generateVoiceArticle = async (
    ThemeContextData, token, lang, voice, desc, categories, projectId, articleId, gpt
) => {
    console.log('Provider');

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const formData = JSON.stringify({
        lang: lang,
        voice: voice,
        desc: desc,
        categories: categories,
        projectId: projectId,
        gpt: gpt,
        article_id: articleId
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formData,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'voice-me/generate-voice',
            requestOptions
        );
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error generating voice:", error);
    }
};

export {
    savedVoices,
    deleteAudio,
    generateVoice,
    generateVoiceArticle,
}