import { getTokenSession } from './Auth';

const getSentimentScore = async (ThemeContextData, token, articleId, article) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        article_id: articleId,
        article: article,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/get-sentiment-score`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            const data = JSON.parse(result.data.data);

            // Inlined logic to format the sentiment data
            const formattedData = [
                ["Anger", data["Anger"], data["Anger Remarks"]],
                ["Clarity", data["Clarity"], data["Clarity Remarks"]],
                ["Confidence", data["Confidence"], data["Confidence Remarks"]],
                ["Excitement", data["Excitement"], data["Excitement Remarks"]],
                ["Optimism", data["Optimism"], data["Optimism Remarks"]],
            ];

            return { success: true, data: formattedData };
        } else {
            return { success: false, error: "Failed to retrieve sentiment score" };
        }
    } catch (error) {
        console.error("Error fetching sentiment score:", error);
        return { success: false, error: "API request failed" };
    }
};

const getSeoScore = async (ThemeContextData, token, articleId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        article_id: articleId,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/get-seo-score`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            const data = JSON.parse(result.data.data);

            // Inlined logic to format the SEO data
            const formattedData = [
                ["Overall Score", data["Overall Score"], data["Overall Score Remarks"]],
                ["Title", data["Title"], data["Title Remarks"]],
                ["Headings", data["Headings"], data["Headings Remarks"]],
                ["Keywords", data["Keywords"], data["Keywords Remarks"]],
                ["Content Quality", data["Content Quality"], data["Content Quality Remarks"]],
                ["Originality", data["Originality"], data["Originality Remarks"]],
                ["User Engagement", data["User Engagement"], data["User Engagement Remarks"]],
            ];

            return { success: true, data: formattedData };
        } else {
            return { success: false, error: "Failed to retrieve SEO score" };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

export {
    getSentimentScore,
    getSeoScore
}