import { getTokenSession, subUser } from "./Auth";

const getCreditsFunction = async (ThemeContextData) => {
    console.log(ThemeContextData);
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    let _credits = null;

    try {
        if (subUser()) {
            let id = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH")).data.id;

            let raw = JSON.stringify({
                subId: id,
            });

            let requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: raw
            };

            const response = await fetch(ThemeContextData.apiURL + 'sub-user/sub-user-credits', requestOptions);
            const result = await response.json();

            if (result.ok) {
                _credits =
                    result.response.user_plan.articles_total < 0
                        ? {
                            articles_total: '0',
                            images_total: '0',
                            month_articles: result.response.user_plan.month_articles,
                            month_images: result.response.user_plan.month_images,
                            month_seconds: result.response.user_plan.month_seconds,
                            month_tabs: result.response.user_plan.month_tabs,
                            seconds_total: '0',
                            tabs_total: result.response.user_plan.tabs_total,
                            user_id: result.response.user_plan.user_id,
                        }
                        : {
                            articles_total: result.response.user_plan.month_articles - result.response.user_plan.articles_total,
                            images_total: result.response.user_plan.month_images - result.response.user_plan.images_total,
                            month_articles: result.response.user_plan.month_articles,
                            month_images: result.response.user_plan.month_images,
                            month_seconds: result.response.user_plan.month_seconds,
                            month_tabs: result.response.user_plan.month_tabs,
                            seconds_total: result.response.user_plan.month_seconds - result.response.user_plan.seconds_total,
                            tabs_total: result.response.user_plan.tabs_total,
                            user_id: result.response.user_plan.user_id,
                        };
            }
        } else {
            let requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(ThemeContextData.apiURL + 'users/user-credits', requestOptions);
            const result = await response.json();

            if (result.ok) {
                _credits =
                    result.response.user_plan.articles_total < 0
                        ? {
                            user_id: result.response.user_plan.user_id,
                            tabs_total: result.response.user_plan.tabs_total,
                            articles_total: "0",
                            month_tabs: result.response.user_plan.month_tabs,
                            month_articles: result.response.user_plan.month_articles,
                        }
                        : result.response.user_plan;
            }
        }
    } catch (error) {
        console.error('Error fetching credits:', error);
    }

    return _credits;  // Ensure the result is returned
}

export {
    getCreditsFunction
}
