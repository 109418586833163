import { Container, Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Worldwide from './../../assets/images/worldwide.png';
import { useContext, useState } from 'react';
import Select from 'react-select';
import {
    getLastName,
    getFirstName,
    getemail,
    getToken,
    getPicture,
    savePicture,
    logout,
    getPassword, getTokenSession,
} from '../../provider/Auth';
import { ThemeContext } from "../../projectContext/ThemeContext";
import WordPress from "../../assets/website/wordpress.png";
import Shopify from "../../assets/website/shopify.png";
import TimeCalendar from './../../assets/images/time-calendar.png';
import { CustomEmailBuilderController } from '../../provider/EmailBuilderControlller';



export default function CreateNewTemplateModal(props) {

    const ThemeContextData = useContext(ThemeContext);
    const [form, setForm] = useState({});


    const handleTextChange = e => {
        setForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    const createNewDomain = async () => {
        let temp_data = {
            "root": {
              "type": "EmailLayout",
              "data": {
                "backdropColor": "#F5F5F5",
                "canvasColor": "#FFFFFF",
                "textColor": "#262626",
                "fontFamily": "MODERN_SANS",
                "childrenIds": []
              }
            }
          };
        const result = await CustomEmailBuilderController(ThemeContextData, form.temp_name, form.temp_descrip, temp_data)
        
        if(result.success){
            window.location.href = `/email-builder?template_id=${result.data.id}`;
        }
    }

    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", "Bearer " + getTokenSession());
    //     myHeaders.append("accept", "application/json");
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         domain_name: form.temp_name,
    //         domain_url: form.temp_descrip,
    //         domain_type: websiteOption.text,
    //     });

    //     var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         redirect: "follow",
    //         body: raw,
    //     };

    //     fetch(
    //         ThemeContextData.apiURL + 'user-domain/save-domain',
    //         requestOptions
    //     )
    //         .then((response) => response.json())
    //         .then((result) => {
    //             setApiKeyActive(true)

    //             if (result.response.ok) {
    //                 props.getAllUserDomain();
    //                 setApiKey(result.response.api_key);
    //             }
    //         })
    // }


    return (
        <>
            <Modal className='credit-report-modal' show={props.show} centered>
                {/* {!apiKeyActive ? */}
                    <>
                        <Modal.Header>
                            <div>
                                <Modal.Title className='fw-semibold flex-grow-1 pb-1'>
                                    <img src={TimeCalendar} className='img-responsove' alt='folder-image' />
                                    Create New Template
                                    <CloseButton
                                        onClick={() => {
                                            props.setCreateNewTemplateModalShow(false);
                                        }}
                                    />
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={(e)=> {e.preventDefault();createNewDomain()}}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Template name</Form.Label>
                                            <Form.Control type="text" name="temp_name" placeholder="Name" value={form.web_name} onChange={handleTextChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Template Description</Form.Label>
                                            <Form.Control as="textarea" row={3} name="temp_descrip" placeholder="Write description.." value={form.add_url} onChange={handleTextChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group>
                                    <Button disabled={form && form.temp_name && form.temp_descrip && form.temp_name.length > 0 && form.temp_descrip.length > 0 ? false : true} variant='primary' type={"submit"} className='px-3'>
                                        <span className='me-2 icon icon-file'>
                                        </span>
                                        Create Template
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </>
            </Modal>
        </>
    )
}