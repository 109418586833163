import { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, DropdownButton, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WriteMe from './../../assets/images/WriteMe.svg';
import Profile from "./../../assets/images/profile-image.png";
import Button from 'react-bootstrap/Button';
import WriteMe2 from "./../../assets/images/WriteMe.svg";
import { ThemeContext } from './../../projectContext/ThemeContext';
import { logout, getTokenSession, getPicture, getemail, getPassword } from "../../provider/Auth";
import dottedLoaderSmall from './../../assets/images/dottedLoaderSmall.gif';

export default function HeaderWithoutMenu(props) {
    const [avatar, setAvatar] = useState("");
    const ThemeContextData = useContext(ThemeContext);
    const [avatarLoader, setAvatarLoader] = useState(false);
    const [notificationData, setNotificationData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [newNotification, setNewNotification] = useState(false);

    const handleShow = () => {
        setShow(true);

        // if (newNotification === true) {
        // viewNotification();
        // }
    }

    const getProfilePic = () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "Get",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(
            ThemeContextData.apiURL + 'users/getApiFilePath',
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.response.ok) {
                    setAvatarLoader(true);
                    setAvatar(result.response.data.url + getPicture());
                }
                else {
                    logout()
                }

            })
            .catch((error) => { });
    }

    const getNotification = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "Get",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(
            ThemeContextData.apiURL + "notifications/user-notifications",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.response.ok) {
                    setNotificationData(result.response.response);
                }
            })
            .catch((error) => { });

    }

    const deleteNotificationHandler = (userId, id) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            notification_id: id,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(
            ThemeContextData.apiURL + 'notifications/mark-read',
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                getNotification();
            })
            .catch((error) => { });
    };

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + getTokenSession());
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "Get",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(
            ThemeContextData.apiURL + 'users/user-subscription',
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                let data = [];
                result.response.data.currentSubscriptionPlan.map((item) => {
                    data.push(item.id);
                })
                localStorage.setItem('user_plan', data);
            })
            .catch((error) => { });

        getNotification();
        getProfilePic();

    }, []);

    return (
        <>
            <div className='header-2'>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col xs={3}>
                            {/* <div className='header-burger-menu d-none'>
                                <Link>
                                    <svg enable-background="new 0 0 32 32" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Layer_1" fill="rgb(0,0,0)">
                                            <path d="m29 8h-26c-1.1045 0-2-.8955-2-2s.8955-2 2-2h26c1.1045 0 2 .8955 2 2s-.8955 2-2 2z" />
                                            <path d="m29 28h-26c-1.1045 0-2-.8955-2-2s.8955-2 2-2h26c1.1045 0 2 .8955 2 2s-.8955 2-2 2z" />
                                            <path d="m29 18h-26c-1.1045 0-2-.8955-2-2s.8955-2 2-2h26c1.1045 0 2 .8955 2 2s-.8955 2-2 2z" />
                                        </g>
                                    </svg>
                                </Link>
                            </div> */}
                            {props.articleGenerator === undefined ? <>
                                {/* {props.articleGenerator} */}
                                <Link to={props && props.previousPage !== undefined ? props.previousPage : '/'} className='fw-medium header-back-btn btn btn-primary-light d-none d-md-inline-block py-2 px-2'>
                                    <span className='icon icon-chevron-left'></span>
                                    {props.backButtonText != undefined ? 'Back to Projects' : 'Back to Dashboard'}
                                </Link>
                                <Link to={'/'} className='fw-medium header-back-btn btn btn-primary-light d-inline-block d-md-none px-2'>
                                    <span className='icon icon-chevron-left m-0 '></span>
                                </Link>
                            </> :
                                <Link onClick={()=> window.location.href = '/'} className='d-flex align-items-center gap-3'>
                                    <svg className='header-chevron-back' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3996 16C11.3208 16 11.2427 15.9845 11.1699 15.9544C11.097 15.9242 11.0308 15.88 10.975 15.8243L3.57566 8.42454C3.46312 8.31197 3.3999 8.15931 3.3999 8.00013C3.3999 7.84095 3.46312 7.68828 3.57566 7.57571L10.975 0.17594C11.0308 0.120179 11.0969 0.0759415 11.1698 0.0457536C11.2426 0.0155657 11.3207 1.85997e-05 11.3995 1.66785e-08C11.4783 -1.85664e-05 11.5564 0.0154918 11.6293 0.0456454C11.7021 0.0757989 11.7683 0.120005 11.8241 0.17574C11.8798 0.231476 11.9241 0.297648 11.9543 0.370479C11.9844 0.443311 12 0.521375 12 0.600215C12 0.679055 11.9845 0.757127 11.9544 0.829973C11.9242 0.902818 11.88 0.969011 11.8243 1.02477L4.84911 7.99993L11.8243 14.9751C11.9083 15.059 11.9655 15.166 11.9887 15.2825C12.0119 15.399 12 15.5197 11.9545 15.6295C11.9091 15.7392 11.8321 15.833 11.7333 15.8989C11.6345 15.9649 11.5184 16.0001 11.3996 16Z" fill="#8D7EF8" />
                                    </svg>
                                    <img src={WriteMe2} className='img-fluid' alt="WriteMe" />
                                </Link>
                            }
                        </Col>
                        <Col xs={6}>

                            {props.articleGenerator === undefined ? <>
                                <div className='logo mx-auto'>
                                    <Link to={'/'}>
                                        <img src={WriteMe} className='img-fluid' alt="WriteMe" />
                                    </Link>
                                </div>
                            </> :
                                <div className='page-title-text text-center d-flex justify-content-center gap-2 align-items-center'>
                                    <span className={props.icon}></span>
                                    {props.text}
                                </div>
                            }

                        </Col>
                        <Col xs={3} className='d-flex align-items-center justify-content-end'>
                            <div className='header-bell-notify position-relative'>
                                <span onClick={handleShow}>
                                    <span className='icon icon-bell'></span>
                                    {newNotification === true ?
                                        <span className='header-bell-notify-active'></span>
                                        : ""}
                                </span>
                            </div>
                            {/* <div className='header-profile-image'>
                                <Link>
                                    <img src={avatar} className='img-fluid' alt='profile-image' />
                                </Link>
                            </div> */}
                            <div className='header-profile-image-holder'>
                                {!avatarLoader ? <div className='header-profile-image'>
                                    <div style={{ width: '36px', height: '36px', display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                        <img src={dottedLoaderSmall} className='img-fluid smallDottedLoader' alt='profile-image' />
                                    </div>
                                </div> :
                                    <DropdownButton className='header-profile-image' title={<img src={avatar} className='img-fluid' alt='profile-image' />}>
                                        <Dropdown.Item href="/setting">
                                            <span className='icon icon-setting'></span>
                                            Account & Settings
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item href="#/action-2">
                                            <span className='icon icon-credit-stroke'></span>
                                            Credits Report
                                        </Dropdown.Item> */}
                                        {/* <Dropdown.Item href=''> */}
                                        <form
                                            method="POST"
                                            action="https://backend.writeme.ai/custom/pushlogin.php"
                                        >
                                            <input
                                                type="hidden"
                                                onChange={() => { }}
                                                value={getemail()}
                                                name="email"
                                            ></input>
                                            <input
                                                type="hidden"
                                                onChange={() => { }}
                                                value={getPassword()}
                                                name="password"
                                            ></input>
                                            <input
                                                type="hidden"
                                                onChange={() => { }}
                                                value="/amember"
                                                name="redirect"
                                            ></input>
                                            <input
                                                type="hidden"
                                                onChange={() => { }}
                                                value="DummyUsername"
                                                name="username"
                                            ></input>
                                            <button
                                                type="submit"
                                                className="btn dropdown-item"
                                            >
                                                <span className='icon icon-subscribe'></span>
                                                Subscription Plans
                                            </button>
                                        </form>
                                        {/* <span className='icon icon-subscribe'></span>
                                            Subscription Plans */}
                                        {/* </Dropdown.Item> */}
                                        <Dropdown.Item target="_blank" href='https://chromewebstore.google.com/detail/writeme-ai-powered-writin/cekebjfebnajphajaemjhgjhpmdjepdo?hl=en-GB'>
                                            <span className='icon icon-extension'></span>
                                            Chrome Extension
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item href="#/action-3">
                                            <span className='icon icon-knowledge'></span>
                                            Knowledge Base
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            <span className='icon icon-API'></span>
                                            API Access
                                        </Dropdown.Item> */}
                                        <Dropdown.Item target="_blank" href="https://writeme.ai/writeme-status/">
                                            <span className='icon icon-system-status'></span>
                                            System Status
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item href="#/action-3">
                                            <span className='icon icon-feedback'></span>
                                            Feedback
                                        </Dropdown.Item> */}
                                        <Dropdown.Divider />
                                        <Dropdown.Item className='btn-only-theme-hover' onClick={() => logout()}>
                                            <span className='icon icon-logout'></span>
                                            Log out
                                        </Dropdown.Item>
                                    </DropdownButton>}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Modal className='notification-modal' show={show} onHide={handleClose} animation={false}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className='icon icon-bell'></span>
                            Notifications
                        </Modal.Title>
                        <span type="button" className="btn-close btn-cross-small" aria-label="Close" onClick={handleClose}></span>
                    </Modal.Header>
                    <hr className='divider' />
                    <Modal.Body className='scroll'>
                        <div className='notification-holder'>
                            {
                                notificationData.map(notifyItem =>
                                    <>
                                        <div className='notification-holder-item card' key={notifyItem.id}>
                                            <h3 className='h2 fw-semibold mb-1'>
                                                {notifyItem.title}
                                            </h3>

                                            <time>
                                                {new Intl.DateTimeFormat("en-GB", {
                                                    month: "long",
                                                    year: "numeric",
                                                    day: "2-digit"
                                                }).format(new Date(notifyItem.created_at))}
                                                <span className='delete-notification ps-3' onClick={() => deleteNotificationHandler(notifyItem.user_id, notifyItem.id)}>Delete</span></time>
                                            <pre>{notifyItem.details}</pre>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}