import { getTokenSession, subUser } from "./Auth";

const SaveEmailBuilderController = async (ThemeContextData, id, htmlContent, json_design) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id,
        content: htmlContent,
        content_json: json_design
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/update-campaign-content`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const SendEmailBuilderController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/update-campaign-content`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const GetEmailBuilderController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        campaign_id: id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}automate-email/get-campaign`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
 
            return { success: true, content_json: JSON.parse( result.response.campaign.content_json ) };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const CustomEmailBuilderController = async (ThemeContextData, temp_name, temp_desc, temp_data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_name:  temp_name,
        template_desc: temp_desc,
        template_data: temp_data
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/create-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
            return { success: true, data: result.response.template };
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const GetCustomEmailBuilderController = async (ThemeContextData, temp_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_id:  temp_id,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/get-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
 
            return { success: true , data: result.response.template};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const UpdateCustomEmailBuilderController = async (ThemeContextData, temp_id, temp_data) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        template_id:  temp_id,
        template_data: temp_data
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/update-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
 
            console.log(result);
            return { success: true , data: result.response.template};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

const GetAllEmailBuilderController = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    // const requestData = JSON.stringify({
    //     template_id:  temp_id,
    // });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        // body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-templates/get-all-email-template`,
            requestOptions
        );
        const result = await response.json();

        if (result.response.ok) {
 
            return { success: true , data: result.response.templates};
        } else {
            return { success: false, error: "Failed to create chat" };
        }
    } catch (error) {
        console.error("Error creating chat:", error);
        return { success: false, error: "API request failed" };
    }
};

export {
    GetEmailBuilderController,
    SaveEmailBuilderController,
    SendEmailBuilderController,
    CustomEmailBuilderController,
    GetCustomEmailBuilderController,
    UpdateCustomEmailBuilderController,
    GetAllEmailBuilderController
}
