import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Trash from './../../assets/images/Trash.png';
import CloseButton from 'react-bootstrap/CloseButton';
import {createList, deleteList} from "../../provider/ReciptListController";

export default function DeleteModal(props) {

    return (
        <>
            <Modal className='deleteModal' show={props.show} centered>
                <Modal.Header >
                    <Modal.Title className='fw-semibold'>
                        <img src={Trash} className='img-responsove' alt='trash-image' />
                        {props.title}
                        <CloseButton onClick={()=> props.closeModal()} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.para}</p>
                    <div className='btn-row-group'>
                        <Button className='btn-danger px-3' onClick={(event) => props.deleteHandler()}><span className='icon-lg icon-delete'></span> Delete</Button>
                        <Button className='btn-secondary px-3' onClick={()=> props.closeModal()}><span className='icon-lg icon-keep'></span> Keep</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}