import { getTokenSession } from './Auth';

const updateArticleDetails = async (ThemeContextData, articleId, articleText, keywords) => {
    let data = [];

    keywords.forEach((item) => {
        let lowerCaseItem = item.toLowerCase();
        let wordCount = document.querySelector('.ql-editor')
            .textContent.toLowerCase()
            .split(lowerCaseItem).length - 1;
        data.push({ 'text': item, word: wordCount });
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({
        article_id: articleId,
        article_text: articleText,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/update-article-generator`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            return { success: true, data };
        } else {
            return { success: false, error: "Failed to update the article." };
        }
    } catch (error) {
        console.error("Error updating article:", error);
        return { success: false, error: "API request failed" };
    }
};

const getArticle = async (ThemeContextData, articleId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestData = JSON.stringify({ article_id: articleId });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}article/get-aricle-generator`,
            requestOptions
        );
        const result = await response.json();

        if (response.ok) {
            const responseData = result.response;

            // Parse SEO data
            let seoDataArray = [];
            if (responseData.result[0]?.seo_score) {
                const seoData = JSON.parse(JSON.parse(responseData.result[0].seo_score));
                seoDataArray = [
                    ["Overall Score", seoData["Overall Score"], seoData["Overall Score Remarks"]],
                    ["Title", seoData["Title"], seoData["Title Remarks"]],
                    ["Headings", seoData["Headings"], seoData["Headings Remarks"]],
                    ["Keywords", seoData["Keywords"], seoData["Keywords Remarks"]],
                    ["Content Quality", seoData["Content Quality"], seoData["Content Quality Remarks"]],
                    ["Originality", seoData["Originality"], seoData["Originality Remarks"]],
                    ["User Engagement", seoData["User Engagement"], seoData["User Engagement Remarks"]],
                ];
            }

            // Parse sentiment analysis data
            let sentimentDataArray = [];
            if (responseData.result[0]?.sentiment_analysis) {
                const sentimentData = JSON.parse(JSON.parse(responseData.result[0].sentiment_analysis));
                sentimentDataArray = [
                    ["Anger", sentimentData["Anger"], sentimentData["Anger Remarks"]],
                    ["Clarity", sentimentData["Clarity"], sentimentData["Clarity Remarks"]],
                    ["Confidence", sentimentData["Confidence"], sentimentData["Confidence Remarks"]],
                    ["Excitement", sentimentData["Excitement"], sentimentData["Excitement Remarks"]],
                    ["Optimism", sentimentData["Optimism"], sentimentData["Optimism Remarks"]],
                ];
            }

            // Parse article text with headings
            let articleText = "";
            if (responseData.result[0]?.article) {
                if (responseData.result[0]?.article.includes('%3C') && responseData.result[0]?.article.includes('%3E')) {
                    articleText = unescape(responseData.result[0]?.article);
                } else {
                    articleText = responseData.result[0].article;
                }
                // if (unescapedArticle.includes('#')) {
                //     articleText = unescapedArticle
                //         .replace(/<br>/g, '\n')
                //         .split('\n')
                //         .map(line => {
                //             if (line.includes('#####')) return `<h5>${line.replace(/#####\s*/, '')}</h5>`;
                //             if (line.includes('####')) return `<h4>${line.replace(/####\s*/, '')}</h4>`;
                //             if (line.includes('###')) return `<h3>${line.replace(/###\s*/, '')}</h3>`;
                //             if (line.includes('##')) return `<h2>${line.replace(/##\s*/, '')}</h2>`;
                //             if (line.includes('#')) return `<h1>${line.replace(/#\s*/, '')}</h1>`;
                //             return line + '\n\n';
                //         })
                //         .join('');
                // } else {
                //     articleText = unescapedArticle;
                // }
            }

            // Prepare categories
            const categoryTitles = responseData.result[0]?.category.map(item => item.title) || [];
            const categoryIds = responseData.result[0]?.category.map(item => item.id) || [];

            return {
                success: true,
                articleText,
                seoDataArray,
                sentimentDataArray,
                keywordCount: responseData.keyword.length,
                wordsLength: responseData.result[0]?.words_length,
                featuredImage: responseData.result[0]?.featured_image,
                time: responseData.result[0]?.cron_time,
                domain: responseData.result[0]?.domain,
                gptVersion: responseData.result[0]?.gpt.includes('gpt-4') ? 'GPT-4' : 'GPT-3.5',
                voiceUrls: responseData.voice?.map(voice => voice.voice_url) || [],
                keywords: responseData.keyword,
                publish_id: responseData.result[0].publish_id,
                categoryTitles,
                categoryIds,
            };
        } else {
            return { success: false, error: "Failed to retrieve article data." };
        }
    } catch (error) {
        console.error("Error fetching article:", error);
        return { success: false, error: "API request failed" };
    }
};


export {
    updateArticleDetails,
    getArticle
};