import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Dropdown, DropdownButton, Button, Col, Container, Form, InputGroup, Row, Modal } from 'react-bootstrap';
import HeaderWithoutMenu from '../../components/HeaderWithoutMenu/HeaderWithoutMenu';
import AddEmailForReciptModal from '../../components/AddEmailForReciptModal/AddEmailForReciptModal';
import CsvImage from '../../assets/file-image/CSV-image.png';
import AddNewListReciptModal from '../../components/AddNewListReciptModal/AddNewListReciptModal';
import EditNewListReciptModal from '../../components/EditNewListReciptModal/EditNewListReciptModal';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import EditEmailForReciptModal from '../../components/EditEmailForReciptModal/EditEmailForReciptModal';
import { CloseButton } from 'react-bootstrap';
import { ThemeContext } from "../../projectContext/ThemeContext";
import { DeleteEmailReciptListController, FileUploadReciptController, getAllRecipientLists } from "../../provider/ReciptListController";
import DeleteList from "../../components/DeleteList/DeleteList";
import dottedLoaderSmall from './../../assets/images/dottedLoaderSmall.gif';
import { DeleteReciptListController } from '../../provider/ReciptListController';
import { CSVDownload, CSVLink } from 'react-csv';


export default function ReciptListAutomate() {

    const [editTableCheckList, setEditTableCheckList] = useState([]);
    const [addEmailReciptModalShow, setAddEmailReciptModalShow] = useState(false);
    const [editEmailReciptModalShow, setEditEmailReciptModalShow] = useState(false);
    const [deleteEmailReciptModalShow, setDeleteEmailReciptModalShow] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [addNewListReciptModalShow, setAddNewListReciptModalShow] = useState(false);
    const [editNewListReciptModalShow, setEditNewListReciptModalShow] = useState(false);
    const [deleteListReciptModalShow, setDeleteListReciptModalShow] = useState(false);
    const [emailTableActive, setEmailTableActive] = useState(false);
    const [exportModalShow, setExportModalShow] = useState(false);
    const ThemeContextData = useContext(ThemeContext);
    const [listId, setListId] = useState(null);
    const [editData, setEditData] = useState();
    const [listData, setListData] = useState([]);
    const [recptList, setRecptList] = useState([]);
    const [recptListTitle, setRecptListTitle] = useState("");
    const [fileType, setFileType] = useState();
    const [csvExportData, setCsvExportData] = useState([]);
    const [loaderShow, setLoaderShow] = useState(false);
    const [searchFilter, setSearchFilter] = useState({
        reciptFilter: '',
        emailFilter: ""
    });
    const [bDownloadReady, setDownloadReady] = useState(false);

    useEffect(() => {
        getListData()
    }, []);

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        const csvFiles = files.filter(file => file.type === 'text/csv');
        setSelectedFiles(csvFiles.map(file => file.name));

        if (e.target.files) {
            setFileType(e.target.files[0]);
        }
    };

    const closeModal = () => {
        setDeleteListReciptModalShow(false);
    }

    const deleteListHandler = async () => {
        try {
            const result = await DeleteReciptListController(ThemeContextData, listId);

            if (result.success) {

                getListData();
                setDeleteListReciptModalShow(false);
            }
        } catch (error) {

        }
    }

    const deleteEmailListHandler = async () => {
        try {
            const result = await DeleteEmailReciptListController(ThemeContextData, editTableCheckList);

            if (result.success) {
                getListData();
                setEmailTableActive(false);
                setEditTableCheckList([]);
                setDeleteEmailReciptModalShow(false)
            }
        } catch (error) {

        }
    }

    const closeEmailModal = () => {
        setDeleteEmailReciptModalShow(false);
    }

    const deleteEmailHandler = () => {
        // setDeleteEmailReciptModalShow(false)
    }

    const getListData = async () => {
        const response = await getAllRecipientLists(ThemeContextData);
        setListData(response.data.lists)
    }
    const upload_file = async () => {
        const response = await FileUploadReciptController(ThemeContextData, listId, fileType);

        if (response.success) {
            setLoaderShow(true);
            const response = await getAllRecipientLists(ThemeContextData);
            setEmailTableActive(false)
            setLoaderShow(false);
            setSelectedFiles([]);
            await getListData();
        }
    }

    const csvLink = useRef();

    useEffect(() => {
        if (csvLink && csvLink.current && bDownloadReady) {
            csvLink.current.link.click();
            setDownloadReady(false);
        }
    }, [bDownloadReady]);

    const handleAction = (actionType) => {
        if (actionType === 'DOWNLOAD') {
            //get data here

            let arrayData = [];
            recptList.map(item => arrayData.push({ 'id': item.id, 'email': item.email, 'first_name': item.first_name, 'last_name': item.last_name, 'user_name': item.first_name + " " + item.last_name }));
            

            setCsvExportData(arrayData);
            setDownloadReady(true);
        }
    }


    return (
        <>
            <HeaderWithoutMenu articleGenerator={true} />
            <main className='automateme-page'>
                {loaderShow ?
                    <Container fluid className='px-0 d-flex align-items-center justify-content-center'>
                        <img src={dottedLoaderSmall} alt='dotted' className='img-fluid' style={{ width: '64px', height: '64px' }} />
                    </Container> :
                    <Container fluid className='px-0 h-100'>
                        <Row className='py-3'>
                            <Col xs={12} className='d-flex justify-content-between align-items-center'>
                                {!emailTableActive ? <>
                                    <h3 className='h2 mb-0'>Recipients</h3>
                                    <Form className='search-recipt-form d-flex gap-2 ml-auto'>
                                        <InputGroup className='search-folder-form mb-0'>
                                            <InputGroup.Text id="basic-addon1"><span className='icon-search'></span></InputGroup.Text>
                                            <Form.Control
                                                placeholder="Search by Title"
                                                aria-label="Search by Title"
                                                value={searchFilter.reciptFilter}
                                                onChange={(e) => {
                                                    setSearchFilter(prev => ({
                                                        ...prev,
                                                        reciptFilter: e.target.value
                                                    }))
                                                }
                                                }
                                            />
                                        </InputGroup>
                                        <Button type='button' variant='primary' onClick={() => setAddNewListReciptModalShow(true)}>
                                            <span className='icon icon-add-circle'></span>
                                            Add New List
                                        </Button>
                                    </Form></> :
                                    <>
                                        <h3 className='h2 mb-0' onClick={() => { setEmailTableActive(false); setEditTableCheckList([]); }}> <svg class="header-chevron-back me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3996 16C11.3208 16 11.2427 15.9845 11.1699 15.9544C11.097 15.9242 11.0308 15.88 10.975 15.8243L3.57566 8.42454C3.46312 8.31197 3.3999 8.15931 3.3999 8.00013C3.3999 7.84095 3.46312 7.68828 3.57566 7.57571L10.975 0.17594C11.0308 0.120179 11.0969 0.0759415 11.1698 0.0457536C11.2426 0.0155657 11.3207 1.85997e-05 11.3995 1.66785e-08C11.4783 -1.85664e-05 11.5564 0.0154918 11.6293 0.0456454C11.7021 0.0757989 11.7683 0.120005 11.8241 0.17574C11.8798 0.231476 11.9241 0.297648 11.9543 0.370479C11.9844 0.443311 12 0.521375 12 0.600215C12 0.679055 11.9845 0.757127 11.9544 0.829973C11.9242 0.902818 11.88 0.969011 11.8243 1.02477L4.84911 7.99993L11.8243 14.9751C11.9083 15.059 11.9655 15.166 11.9887 15.2825C12.0119 15.399 12 15.5197 11.9545 15.6295C11.9091 15.7392 11.8321 15.833 11.7333 15.8989C11.6345 15.9649 11.5184 16.0001 11.3996 16Z" fill="#000"></path></svg>
                                            {recptListTitle}
                                        </h3>
                                        <Form className='search-recipt-form d-flex gap-2 ml-auto'>
                                            <InputGroup className='search-folder-form mb-0'>
                                                <InputGroup.Text id="basic-addon1"><span className='icon-search'></span></InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Search by email"
                                                    aria-label="Search by email"
                                                    value={searchFilter.emailFilter}
                                                    onChange={(e) => {
                                                        setSearchFilter(prev => ({
                                                            ...prev,
                                                            emailFilter: e.target.value
                                                        }))
                                                    }}
                                                />
                                            </InputGroup>
                                            <Button type='button' variant='primary' onClick={() => setAddEmailReciptModalShow(true)}>
                                                <span className='icon icon-add-circle'></span>
                                                Add User Email
                                            </Button>
                                        </Form>
                                    </>
                                }
                            </Col>
                        </Row>
                        {!emailTableActive ?
                            <div className='scroll recripts-card-design-holder'>
                                {listData.map((list) => (
                                    <article
                                        className={searchFilter.reciptFilter.length >= 1 && !list.list_name.toLowerCase().includes(searchFilter.reciptFilter) ? "d-none card recipt-card-design p-3 gap-2" : "card recipt-card-design p-3 gap-2"}
                                    >
                                        <div>
                                            <h3 className='h4 mb-1'>{list.list_name}</h3>
                                            <p>{list.list_desc}</p>
                                            <span onClick={() => {
                                                setRecptList(list.list_data);
                                                setRecptListTitle(list.list_name)
                                                setListId(list.id);
                                                setEmailTableActive(true)
                                            }} className='views'>{list.list_dataCount} • View</span>
                                        </div>
                                        <DropdownButton className='card-dropdown align-right' drop="start"
                                            id="dropdown-item-button" title={<span className="icon-dots"></span>}>
                                            <Dropdown.Item as="button" onClick={() => {
                                                setEditData(list);
                                                setEditNewListReciptModalShow(true);
                                            }}><span
                                                className='icon-edit'></span> Edit List</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={() => {
                                                setListId(list.id);
                                                setDeleteListReciptModalShow(true)
                                            }}
                                                className='delete'><span className='icon-delete'></span> Delete List</Dropdown.Item>
                                        </DropdownButton>
                                    </article>
                                ))
                                }
                            </div> :
                            <div className='recipt-holder d-flex'>
                                <div className='recipt-align-left scroll'>
                                    <div className='recipt-edited-holder'>
                                        <ul className='list-unstyled recipt-edited-table-btn-list mb-0'>
                                            <li>
                                                <span className='fw-medium'>{editTableCheckList.length}</span>
                                                <span> Selected</span>
                                            </li>
                                            <li onClick={(e) => handleAction('DOWNLOAD')}>
                                                <span className='icon icon-download'></span>
                                                <span> Export</span>
                                                <CSVLink
                                                    data={csvExportData}
                                                    filename="data.csv"
                                                    className="hidden"
                                                    ref={csvLink}
                                                    target="_blank" />
                                            </li>
                                            <li className='danger-text' style={{ cursor: "pointer" }} onClick={() => setDeleteEmailReciptModalShow(true)}>
                                                <span className='icon icon-delete'></span>
                                                <span> Delete</span>
                                            </li>
                                        </ul>
                                        <table className='recipt-edited-table table'>
                                            <thead>
                                                <tr>
                                                    <th className='email'>
                                                        <div className='gap-2 recipt-edited-table-email-check'>
                                                            <input type="checkbox" id='email-address' name="Select All"
                                                                checked={recptList.length === editTableCheckList.length}
                                                                onClick={(e) => {
                                                                    if (e.target.checked) {
                                                                        const arrayData = recptList.map(item => item.id);
                                                                        setEditTableCheckList(arrayData);

                                                                    } else {
                                                                        setEditTableCheckList([]);
                                                                    }
                                                                }} />
                                                            <label style={{ cursor: "pointer" }} htmlFor="email-address">Email Address</label>
                                                        </div>
                                                    </th>
                                                    <th className='first-name'>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>User Name</th>
                                                    <th>Last Changed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recptList.map((recipient) => (
                                                    <tr key={recipient.id}
                                                        className={searchFilter.emailFilter.length >= 1 && !recipient.email.toLowerCase().includes(searchFilter.emailFilter) ? "d-none" : ""}
                                                    >
                                                        <td className='email'>
                                                            <div className='gap-2 recipt-edited-table-email-check'>
                                                                <input
                                                                    type="checkbox"
                                                                    id={recipient.id}
                                                                    name="Select All"
                                                                    checked={editTableCheckList.includes(recipient.id)}
                                                                    onClick={(e) => {
                                                                        if (editTableCheckList.includes(recipient.id)) {
                                                                            let arrayIndex = editTableCheckList.indexOf(recipient.id);
                                                                            editTableCheckList.splice(arrayIndex, 1)
                                                                            setEditTableCheckList([...editTableCheckList]);
                                                                        } else {
                                                                            setEditTableCheckList([...editTableCheckList, recipient.id]);
                                                                        }
                                                                    }}
                                                                />
                                                                <label htmlFor={recipient.id}>{recipient.email}</label>
                                                            </div>
                                                        </td>
                                                        <td className='first-name'>{recipient.first_name}</td>
                                                        <td className='last-name'>{recipient.last_name}</td>
                                                        <td className='user-name'>{`${recipient.first_name} ${recipient.last_name}`}</td>
                                                        <td className='last-change'>{new Date(recipient.updated_at).toLocaleString()}</td>
                                                    </tr>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='recipt-align-right d-flex flex-column mb-3'>
                                    <div className='file-upload-holder'>
                                        <input type="file" accept=".csv" onChange={handleFileChange} name="" id="upload-csv" />
                                        <label htmlFor="upload-csv">
                                            <div>
                                                <svg className='mx-auto' width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.4877 20.9265L17.5066 14.9453L11.5254 20.9265" stroke="black" stroke-opacity="0.4" stroke-width="1.49529" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M17.502 14.9453V28.4029" stroke="black" stroke-opacity="0.4" stroke-width="1.49529" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M30.0505 24.5011C31.5089 23.706 32.661 22.4478 33.325 20.9253C33.989 19.4027 34.127 17.7023 33.7173 16.0926C33.3076 14.4828 32.3734 13.0554 31.0623 12.0355C29.7512 11.0156 28.1378 10.4614 26.4768 10.4603H24.5927C24.1401 8.70964 23.2965 7.0844 22.1254 5.70672C20.9543 4.32905 19.486 3.23479 17.8311 2.50622C16.1762 1.77765 14.3777 1.43372 12.5707 1.5003C10.7638 1.56688 8.9954 2.04222 7.3986 2.8906C5.80179 3.73897 4.41808 4.93831 3.35151 6.39843C2.28493 7.85855 1.56324 9.54146 1.24069 11.3206C0.918141 13.0998 1.00313 14.929 1.48926 16.6706C1.97539 18.4122 2.85002 20.021 4.04738 21.3759" stroke="black" stroke-opacity="0.4" stroke-width="1.49529" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23.4877 20.9265L17.5066 14.9453L11.5254 20.9265" stroke="black" stroke-opacity="0.4" stroke-width="1.49529" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                Upload CSV file
                                                <span className='btn btn-primary text-uppercase'>Select file</span>
                                            </div>
                                        </label>
                                    </div>
                                    <ul className="file-upload-list d-flex list-unstyled">
                                        {selectedFiles.length > 0 && selectedFiles.map((fileName, index) => (
                                            <li key={index}>
                                                <img src={CsvImage} alt='file tyle' /> {fileName} . <span className='link'>Preview</span>
                                                <svg onClick={() => { setSelectedFiles([]) }}
                                                    className='delete' width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.4375 1.875H6.875V1.5625C6.875 1.31386 6.77623 1.0754 6.60041 0.899587C6.4246 0.723772 6.18614 0.625 5.9375 0.625H4.0625C3.81386 0.625 3.5754 0.723772 3.39959 0.899587C3.22377 1.0754 3.125 1.31386 3.125 1.5625V1.875H1.5625C1.47962 1.875 1.40013 1.90792 1.34153 1.96653C1.28292 2.02513 1.25 2.10462 1.25 2.1875C1.25 2.27038 1.28292 2.34987 1.34153 2.40847C1.40013 2.46708 1.47962 2.5 1.5625 2.5H1.875V8.125C1.875 8.29076 1.94085 8.44973 2.05806 8.56694C2.17527 8.68415 2.33424 8.75 2.5 8.75H7.5C7.66576 8.75 7.82473 8.68415 7.94194 8.56694C8.05915 8.44973 8.125 8.29076 8.125 8.125V2.5H8.4375C8.52038 2.5 8.59987 2.46708 8.65847 2.40847C8.71708 2.34987 8.75 2.27038 8.75 2.1875C8.75 2.10462 8.71708 2.02513 8.65847 1.96653C8.59987 1.90792 8.52038 1.875 8.4375 1.875ZM4.375 6.5625C4.375 6.64538 4.34208 6.72487 4.28347 6.78347C4.22487 6.84208 4.14538 6.875 4.0625 6.875C3.97962 6.875 3.90013 6.84208 3.84153 6.78347C3.78292 6.72487 3.75 6.64538 3.75 6.5625V4.0625C3.75 3.97962 3.78292 3.90013 3.84153 3.84153C3.90013 3.78292 3.97962 3.75 4.0625 3.75C4.14538 3.75 4.22487 3.78292 4.28347 3.84153C4.34208 3.90013 4.375 3.97962 4.375 4.0625V6.5625ZM6.25 6.5625C6.25 6.64538 6.21708 6.72487 6.15847 6.78347C6.09987 6.84208 6.02038 6.875 5.9375 6.875C5.85462 6.875 5.77513 6.84208 5.71653 6.78347C5.65792 6.72487 5.625 6.64538 5.625 6.5625V4.0625C5.625 3.97962 5.65792 3.90013 5.71653 3.84153C5.77513 3.78292 5.85462 3.75 5.9375 3.75C6.02038 3.75 6.09987 3.78292 6.15847 3.84153C6.21708 3.90013 6.25 3.97962 6.25 4.0625V6.5625ZM6.25 1.875H3.75V1.5625C3.75 1.47962 3.78292 1.40013 3.84153 1.34153C3.90013 1.28292 3.97962 1.25 4.0625 1.25H5.9375C6.02038 1.25 6.09987 1.28292 6.15847 1.34153C6.21708 1.40013 6.25 1.47962 6.25 1.5625V1.875Z" fill="#FF4A55" />
                                                </svg>
                                            </li>
                                        ))}
                                    </ul>
                                    {selectedFiles.length > 0 ?
                                        <Button
                                            type='button'
                                            variant='primary' className='w-100 gap-2'
                                            onClick={(e) => { upload_file(e); }}
                                        >
                                            Upload
                                            <span className='icon-sm icon-chevron-right'></span>
                                        </Button> :
                                        ""}
                                </div>
                            </div>
                        }
                    </Container>

                }
            </main >
            <AddNewListReciptModal show={addNewListReciptModalShow} setAddNewListReciptModalShow={setAddNewListReciptModalShow} themeContext={ThemeContextData} getListData={getListData} />
            <EditNewListReciptModal show={editNewListReciptModalShow} setEditNewListReciptModalShow={setEditNewListReciptModalShow} themeContext={ThemeContextData} getListData={getListData} editData={editData} />
            <DeleteModal title="Delete this list?" para={"Are you sure you want to delete this list?"} show={deleteListReciptModalShow} closeModal={closeModal} getListData={getListData} themeContext={ThemeContextData} deleteHandler={deleteListHandler} getListId={listId} />
            {addEmailReciptModalShow ? <AddEmailForReciptModal show={addEmailReciptModalShow} themeContext={ThemeContextData} list_id={listId} setAddEmailReciptModalShow={setAddEmailReciptModalShow} getListData={getListData} /> : ""}
            <EditEmailForReciptModal show={editEmailReciptModalShow} setEditEmailReciptModalShow={setEditEmailReciptModalShow} />
            <DeleteModal title="Delete this email List?" para={"Are you sure you want to delete this email list?"} show={deleteEmailReciptModalShow} closeModal={closeEmailModal} deleteHandler={deleteEmailListHandler} />
            <Modal className='deleteModal' show={exportModalShow} centered>
                <Modal.Header >
                    <Modal.Title className='fw-semibold'>
                        <span className='icon icon-download'></span>
                        Export
                        <CloseButton onClick={() => setExportModalShow(false)} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to export this list?</p>
                    <div className='btn-row-group gap-3 align-items-center'>
                        <span className='fw-medium' onClick={() => setExportModalShow(false)}><span className='icon icon-cross-small'></span> Close</span>
                        <Button className='btn-secondary px-3' onClick={() => setExportModalShow(false)}><span className='icon icon-download'></span> Confirm Export</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
