import { getTokenSession } from './Auth';

const fetchUserDomains = async (ThemeContextData, setUserDomains) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'user-domain/all-user-domain',
            requestOptions
        );
        const result = await response.json();

        if (result.response) {
            const data = result.response.domains.map((item) => ({
                value: item.domain_name,
                text: item.domain_name,
                id: item.id
            }));

            setUserDomains(data); // Update the state with the fetched data
        }
    } catch (error) {
        console.error("Error fetching user domains:", error);
    }
};

const scheduleArticleAPI = async (ThemeContextData, articleId, date, timeValue, domainId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        article_id: articleId,
        date: date,
        time: timeValue,
        domain: domainId
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/schedule-article-now',
            requestOptions
        );
        const result = await response.json();

        return result.ok;  // Return success or failure
    } catch (error) {
        console.error("Error scheduling article:", error);
        return false;
    }
};

const publishArticleAPI = async (ThemeContextData, articleId, domainId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        article_id: articleId,
        domain: domainId
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/publish-wordpress',
            requestOptions
        );
        const result = await response.json();

        return result.ok;  // Return whether the publish was successful
    } catch (error) {
        console.error("Error publishing article:", error);
        return false;
    }
};

const updateArticleAPI = async (ThemeContextData, articleId, voiceUrl) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        article_id: articleId,
        voice_url: voiceUrl
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/update-wordpress',
            requestOptions
        );
        const result = await response.json();

        return result.ok; // Return whether the update was successful
    } catch (error) {
        console.error("Error updating article:", error);
        return false;
    }
};

const getArticleFromWPAPI = async (ThemeContextData, articleId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        article_id: articleId,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/update-from-wordpress',
            requestOptions
        );
        const result = await response.json();
        return result; // Return the result for further processing
    } catch (error) {
        console.error("Error fetching article from WordPress:", error);
        return null; // Return null in case of error
    }
};

const fetchPictureMeAPI = async (ThemeContextData, projectId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        projectId: projectId,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'picture-me/project-images',
            requestOptions
        );
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error fetching Picture Me images:", error);
        return null;
    }
};

const fetchFreepikImages = async (ThemeContextData, searchField) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        search: searchField,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'picture-me/freepik',
            requestOptions
        );
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error fetching Freepik images:", error);
        return null;
    }
};

const uploadFeaturedImage = async (ThemeContextData, selectedFile, articleId, imageDetails) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('url', imageDetails?.url);
    formData.append('type', imageDetails?.type);
    formData.append('article_id', articleId);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'article/set-featured-image',
            requestOptions
        );
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error uploading featured image:", error);
        return null;
    }
};

const uploadGalleryImage = async (ThemeContextData, selectedFiles) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());

    const formData = new FormData();
    formData.append('image', selectedFiles);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'picture-me/upload-user-image',
            requestOptions
        );
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error uploading featured image:", error);
        return null;
    }
};

const getGalleryImage = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());


    const requestOptions = {
        method: "POST",
        headers: myHeaders,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'picture-me/my-uploaded-images',
            requestOptions
        );
        const result = await response.json();
        console.log(result);

        return {success: true, data: result.response };
    } catch (error) {
        console.error("Error uploading featured image:", error);
        return {success: false};
    }
};



const generateVoice = async (
    ThemeContextData, token, lang, voice, desc, categories, projectId, articleId, gpt
) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const formData = JSON.stringify({
        lang: lang,
        voice: voice.toLowerCase(),
        desc: desc,
        categories: categories,
        projectId: projectId,
        article_id: articleId,
        gpt: gpt,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: formData,
    };

    try {
        const response = await fetch(
            ThemeContextData.apiURL + 'voice-me/generate-voice',
            requestOptions
        );
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error generating voice:", error);
        return null;
    }
};

export{
    fetchUserDomains,
    scheduleArticleAPI,
    publishArticleAPI,
    updateArticleAPI,
    getArticleFromWPAPI,
    fetchPictureMeAPI,
    fetchFreepikImages,
    uploadFeaturedImage,
    uploadGalleryImage,
    getGalleryImage
}