import { getTokenSession } from './Auth';

const addSendingMethod = async (ThemeContextData, data) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        // article_id: articleId,
        host: data.host,
        port: data.port,
        email: data.email,
        password: data.password,
        secure: data.secure
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        // redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/add-smtp-sender`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

const getAllSendingMethod = async (ThemeContextData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/get-smtp-sender`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true , data: result.response.smtps};
        } else {
            return { success: false};
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }
}

const deleteSendingMethodController = async (ThemeContextData, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        smtp_id: id,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/delete-smtp-sender`,
            requestOptions
        );

        const result = await response.json();

        if (result.response.ok) {
            return { success: true};
        } else {
            return { success: false};
        }
    } catch (error) {
        return { success: false, error: "API request failed" };
    }
}

const updateSendingMethodController = async (ThemeContextData, data, id) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + getTokenSession());
    myHeaders.append("Content-Type", "application/json");
    const requestData = JSON.stringify({
        smtp_id: id,
        host: data.host,
        port: data.port,
        email: data.email,
        password: data.password,
        secure: data.secure
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestData,
        // redirect: "follow",
    };

    try {
        const response = await fetch(
            `${ThemeContextData.apiURL}email-sender/edit-smpt-sender`,
            requestOptions
        );

        const result = await response.json();
        if (result.response.ok) {
            return { success: true };
        } else {
            return { success: false, };
        }
    } catch (error) {
        console.error("Error fetching SEO score:", error);
        return { success: false, error: "API request failed" };
    }
};

export {
    addSendingMethod,
    getAllSendingMethod,
    deleteSendingMethodController,
    updateSendingMethodController
}