import { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ProjectArticle from './../../assets/images/Project-article.png';
import Form from 'react-bootstrap/Form';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Calendar from "react-calendar";
import { getTokenSession } from "../../provider/Auth";
import { ThemeContext } from "../../projectContext/ThemeContext";
import SelectDropDown from '../SelectDropDown/SelectDropDown';
import { CloseButton } from 'react-bootstrap';
import LoaderJSON from './../../assets/images/loading.json';
import Lottie from 'lottie-react';
import {publishArticleAPI} from "../../provider/ArticleWriterControllerTools";



export default function PublishNowModal(props) {

    const [publishOption, setPublishOption] = useState({
        'value': '',
        'text': 'Select your publish destination',
        'icon': '',
        'code': ''
    });
    const ThemeContextData = useContext(ThemeContext);
    const [userDomains, setUserDomains] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setUserDomains(props.userDomains)
    })


    const publishChange = e => {
        setPublishOption(e);
    }

    const publishArticle = async () => {
        setLoader(true);

        const success = await publishArticleAPI(
            ThemeContextData,
            props.articleId,
            publishOption.id
        );

        if (success) {
            props.publishCloseModal();
        }

        setLoader(false);
    }



    return (
        <>
            <Modal className='CreateInstanceModal customModal' show={props.show} centered>
                <Modal.Header className='flex-wrap justify-content-center'>
                    <div>
                        <Modal.Title className='justify-content-center mb-2 small'> <img src={ProjectArticle} className='img-responsove' alt='Time Calendar' /> Publish Article</Modal.Title>
                        <p className='mb-0 text-center'>Fill out the details below to schedule your article for generation.</p>
                    </div>
                    <CloseButton onClick={() => props.publishCloseModal()} />
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                            <Col xs={12}>
                                <SelectDropDown
                                    class={'select-custom-field'}
                                    label={"Publish Destination"}
                                    value={publishOption}
                                    name={"publishField"}
                                    option={userDomains}
                                    callfunction={publishChange}
                                    type={"icon"} />
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center">
                                {!loader ?
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            publishArticle()
                                        }}>
                                        <span className='icon-lg icon-magic'></span>
                                        Publish Now
                                    </Button> :
                                    <Button variant='dark' className='mx-auto' onClick={(e) => { e.preventDefault(); }}>
                                        <Lottie className='LoaderJSONSVG' animationData={LoaderJSON} />
                                        Generating...
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}